<template>
  <div class="mainContent">
    <div class="inner">
      <div class="row">
        <div class="col2">
          <h1>Seit 15 Jahren begleite ich Kinder in ihrer Entwicklung</h1>
          <p>Mein Name ist Leandra Agazzi, die Ausbildung zur Fachperson Betreuung EFZ habe ich in einer Bildungskita im Jahr 2015 erfolgreich abgeschlossen und begleite seither Kinder jeden Alters mit Einfühlungsvermögen und Interesse. 
          </p>
            <br>
            <p>Besonderen Wert lege ich auf die individuellen Bedürfnisse der Kinder und gestalte Angebote welche gezielt die Entfaltung ihrer Fähigkeiten fördern, darum habe ich nach meinem Masterabschluss in Fine Arts einen Ort mit gestalterischem Fokus für Kinder geschaffen.
            </p>
            <br><br>
          <h2>Ausbildung</h2>
            <p> 2010 - 2012 Praktika in Kitas</p>
            <p> 2012 - 2015 Fachfrau Betreuung Kind EFZ</p>
            <p> 2015 - 2018 Gestalterische Berufsmaturität</p>
            <p> 2018 - 2021 Bachelor Fine Art, Zürcher Hochschule der Künste</p>
            <p> 2021 - 2023 Master Fine Art, Zürcher Hochschule der Künste</p>
        
        </div>
        <div class="col3">
          <img src="../assets/img/about.jpg">
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutView',
  components: {
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {};
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {},
};
</script>
<style lang="scss">
</style>