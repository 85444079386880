<template>
    <div class="mainContent">
    <div class="inner">
      <div class="row" >
        <div class="col2" v-if="!send">
          <label for="html">Name</label><br>
          <input class="formFiled" type="text" name="name" v-model="name"/>
          <label for="html">E-Mail</label><br>
          <input class="formFiled" type="text" name="email" v-model="email"/>
          <label for="html">Telefon</label><br>
          <input class="formFiled" type="text" name="telefon" v-model="telefon"/>
          <label for="html">Nachricht</label><br>
          <textarea  class="formFiled" name="nachricht" v-model="nachricht"></textarea>
          <button @click.prevent="submitForm" style="margin-bottom:32px;">SENDEN</button>
        </div>
        <div class="col2" v-if="send">
          Ihre Nachricht wurde erfolgreich versendet. Wir werden uns in Kürze bei Ihnen melden.
        </div>
        <div class="col2">
          <iframe width="100%" height="450" allowfullscreen="" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" loading="lazy" referrerpolicy="no-referrer-when-downgrade" src="https://maps.google.com/maps?width=550&amp;height=450&amp;hl=en&amp;q=Badenerstrasse%20556%20Zurich+Malspiel%20Z%C3%BCrich&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const WEB3FORMS_ACCESS_KEY = "c2162ef6-f42e-4639-ab54-560084476a0a";
  export default {
    name: 'KontaktView',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        send:false,
        name:"",
        email:"",
        telefon:"",
        nachricht:""
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async submitForm() {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            access_key: WEB3FORMS_ACCESS_KEY,
            name: this.name,
            telefon: this.telefon, 
            email: this.email,
            nachricht:this.nachricht,            
          }),
        });
        const result = await response.json();
        if (result.success) {
          console.log(result);
          this.send = true;
        }
      },
    },
  };
</script>
<style lang="scss">
</style>