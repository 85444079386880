<template>
  <div class="mainContent">
    <div class="inner">
      <div class="row">
        <div class="col2">
          <h1>Im Malspiel lernen Kinder kreativ und spielend</h1>
          <div class="homeTagsHead">Die Methode des Malspiels:</div>
          <div class="homeTags">Stärkt die Persönlichkeit und das Selbstvertrauen.</div>
          <div class="homeTags">Fördert gezielt die Konzentration und die motorischen Fähigkeiten.</div>
          <div class="homeTags">Hilft eigene Bedürfnisse wahrzunehmen und auszudrücken.</div>
          <div class="homeTags">Regt das individuelle Vorstellungsvermögen und die Kreativität an.</div>
          <div class="homeTags">Motiviert eigene Lösungswege zu finden und auszuprobieren.</div>
          <div class="homeTags">Ermutigt Entscheidungen zu treffen und Verantwortung zu übernehmen.</div>
        </div>
        <div class="col3">
          <img src="../assets/img/home.jpg">
        </div>
      </div>
      <div class="row">
        <button @click="$router.push({ path: '/anmeldung' })">ZUR ANMELDUNG</button><button @click="$router.push({ path: '/angebot' })">ZUM ANGEBOT</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeView',
  components: {
  },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {},
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {};
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {},
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {},
};
</script>
<style lang="scss">
</style>