<template>
    <div class="mainContent">
    <div class="inner">
      <div class="row" v-if="!send">
        <div class="col2">
            <label for="html">Vorname</label><br>
            <input class="formFiled" type="text" name="vorname" v-model="vorname"/>

            <label for="html">Name</label><br>
            <input class="formFiled"  type="text" name="name"  v-model="name"/>

            <label for="html">Strasse/Nr.</label><br>
            <input class="formFiled"  type="text" name="adresse"  v-model="adresse"/>

            <label for="html">PLZ/Ort</label><br>
            <input class="formFiled"  type="text" name="plzort"  v-model="plzort"/>

            <label for="html">Bevorzugte Kontakaufnahme</label><br>
            <select id="tag" name="contact" v-model="contact" class="formFiled">
              <option value="E-Mail" selected>E-Mail</option>
              <option value="SMS">SMS</option>
              <option value="Telefon">Telefon</option>
            </select>
          </div>
        <div class="col2">
          <label for="html">Telefon</label><br>
          <input class="formFiled"  type="text" name="telefon"  v-model="telefon"/>
          <label for="html">E-Mail</label><br>
          <input class="formFiled"  type="text" name="email"  v-model="email"/>
          <label for="html">Vorname Kind(er)</label><br>
          <input class="formFiled"  type="text" name="namekind"  v-model="namekind"/>
          <label for="html">Alter Kind(er)</label><br>
          <input class="formFiled"  type="text" name="alter"  v-model="alter"/>
          <label for="html">Bevorzugte Wochentage</label><br>
            <select id="tag" name="tag" v-model="tag" class="formFiled">
              <option value="Montag">Montag</option>
              <option value="Dienstag">Dienstag</option>
              <option value="Mittwoch">Mittwoch</option>
              <option value="Samstag" >Samstag</option>
              <option value="Individuell" >Individuell</option>
            </select>
        </div>
      </div>
      <div class="row" v-if="send">
        Ihre Anmeldung wurde erfolgreich versendet. Wir werden uns in Kürze bei Ihnen melden.
      </div>
      <div class="row" v-if="!send">
        <button @click.prevent="submitForm">ANMELDEN</button>
      </div>
    </div>
  </div>
</template>
<script>
  const WEB3FORMS_ACCESS_KEY = "c2162ef6-f42e-4639-ab54-560084476a0a";
  export default {
    name: 'AnmeldungView',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        send:false,
        vorname: "",
        name: "",
        adresse:"",
        plzort:"",
        telefon: "",
        email: "",
        namekind:"",
        alter: "",
        tag:"Montag",
        contact:"E-Mail",
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {
      async submitForm() {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            access_key: WEB3FORMS_ACCESS_KEY,
            vorname: this.vorname,
            name: this.name,
            adresse: this.adresse,
            plzOrt: this.plzort,
            telefon: this.telefon, 
            email: this.email,
            nameKind: this.namekind,
            alter: this.alter,
            tag:this.tag,
            contact:this.contact,            
          }),
        });
        const result = await response.json();
        if (result.success) {
          console.log(result);
          this.send = true;
        }
      },
    },
  };
</script>
<style lang="scss">
</style>