<template>
      <div class="mainHeader desktopOnly">
        <div class="innerRow">
          <div class="mainLogo">
            <router-link to="/" ><img src="../assets/img/logo.png"></router-link>
          </div>
          <nav>
            <ul>
              <li v-for="(item, x) in mainNavigation" :key='x' ><router-link :to="item.url" @click="showMenu = false">{{item.name}}</router-link></li>
            </ul>
          </nav>
        </div>
       <!-- <div class="innerRow lineTop"><img src="../assets/img/linie.png"></div> -->
      </div>
     <div class="mainHeaderMobile mobileOnly">
        <div class="innerRowMobile">
          <div class="mainLogoMobile">
            <router-link to="/" ><img src="../assets/img/logo.png"></router-link>
          </div>
          <div class="mobileMenuBtn" @click="mobileMenu">
            <img src="../assets/img/menu.png">
          </div>
         
      </div> 
     
      </div>
      <transition name="fade">
        <div class="mainMenuMobile" v-if="showMenu">

          <div class="mainHeaderMobile mobileOnly">
        <div class="innerRowMobile">
          <div class="mainLogoMobile">
            <router-link to="/" ><img src="../assets/img/logo.png"></router-link>
          </div>
          <div class="mobileMenuBtn" @click="mobileMenu">
            <img src="../assets/img/menu.png">
          </div>
      </div> 
          </div> 
            <div  class="navMobileInner" style="">
            <nav>
              <ul>
                <li v-for="(item, x) in mainNavigation" :key='x' ><a href="#" @click.prevent="mobileNavigation(item, x)" :class="{activeMenu: item.active}">{{item.name}}</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </transition>
</template>
<script>
  export default {
    name: 'MainNav',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
        showMenu:false,
        mainNavigation:[
          {
            name: "Home",
            active: true,
            url: "/"
          },
          {
            name: "Konzept",
            active: false,
            url: "/konzept"
          },
          {
            name: "Angebot",
            active: false,
            url: "/angebot"
          },
          {
            name: "Anmeldung",
            active: false,
            url: "/anmeldung"
          },
          {
            name: "Über Mich",
            active: false,
            url: "/about"
          },
          {
            name: "Kontakt",
            active: false,
            url: "/kontakt"
          }
        ]
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {

      mobileNavigation(item, x){
        this.$router.push(item.url);
        console.log(x);
        this.showMenu = false;

        let arrayLength = this.mainNavigation.length;
        for(let i = 0 ; i < arrayLength; i++) {
            this.mainNavigation[i].active =false;
        }
        this.mainNavigation[x].active = true;

      },
      mobileMenu(){
        console.log("FIRE");
        if(this.showMenu === false){
          this.showMenu = true;
        }
        else if(this.showMenu === true){
          this.showMenu = false;
        }
      }
    },
  };
</script>
<style lang="scss">
  
</style>