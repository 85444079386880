import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import KonzeptView from '../views/KonzeptView.vue'
import AngebotView from '../views/AngebotView.vue'
import AnmeldungView from '../views/AnmeldungView.vue'
import AboutView from '../views/AboutView.vue'
import KontaktView from '../views/KontaktView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/konzept',
    name: 'konzept',
    component: KonzeptView
  },
  {
    path: '/angebot',
    name: 'angebot',
    component: AngebotView
  },
  {
    path: '/anmeldung',
    name: 'anmeldung',
    component: AnmeldungView
  },
  {
    path: '/about',
    name: 'about', 
    component: AboutView
  },
  {
    path: '/kontakt',
    name: 'kontakt', 
    component: KontaktView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
