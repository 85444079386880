<template>
    <div class="mainContent">
    <div class="inner">
      <div class="row">
        <div class="col2">
          <h1>Unser buntes Angebot</h1>

          <p>Alter 4 - 12 Jahre. Einstieg ist jederzeit möglich.</p>
          <p>Anmeldung bitte telefonisch, per Email oder online.</p>
          <br>
          <h2>Lektionen</h2>
          <p>Montag: <br>
             16:45 - 18:00 Uhr </p>
             
          <p>Dienstag: <br>
             16:45 - 18:00 Uhr</p>
       
          <p>Mittwoch: <br>
             16:45 - 18:00 Uhr</p>
         
          <p>Samstag: <br>
              09:45 - 11:00 Uhr <br>
              13:45 - 15:00 Uhr <br>
              Änderungen sind vorbehalten.</p>

              <br>
              <h2>Preise</h2>
              <p>
              Probelektion = CHF 20.- <br>
              Einzellektion = CHF 38.- <br>
              10 Lektionen = CHF 330.- (33.- / Lektion)<br>
              Semester = CHF 580.- (29.- / Lektion)<br>
              Inkusive Material und Znüni / Zvieri <br>
              Eine Lektion dauert 75 Minuten.
          </p>

        </div>
        <div class="col3">
          <img src="../assets/img/angebot.jpg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AngebotView',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>