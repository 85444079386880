<template>
  <div>
    <MainNav></MainNav>
      <router-view v-slot="{ Component }">
       <!-- <transition name="slide"> -->
          <component :is="Component" />
       <!-- </transition> -->
      </router-view>
    <div class="mainFooter">
      <!-- <div class="innerRow lineBottom"><img src="./assets/img/linie.png"></div> -->
      <div class="innerRow">Malspiel Zürich, Badenerstrasse 556, 8048 Zürich, Tel.: 044 586 67 57</div>
      
    </div>
  </div>
</template>
<script>
import MainNav from '@/components/MainNav.vue';
  export default {
    name: 'App',
    components: {
      MainNav
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {
      };
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">

@import "./scss/app";

</style>
