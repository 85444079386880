<template>
    <div class="mainContent">
    <div class="inner">
      <div class="row">
        <div class="col2">
          <h1>Begleitetes Malen zur ganzheitlichen Entwicklung von Kindern</h1>
          <h2>Das Malspiel basierend auf der Pädagogik von Arno Stern und Maria Montessori wird professionell begleitet durch eine ausgebildete Fachperson mit 15 Jahren Erfahrung.</h2>
          <br>
          <p>Im Malspiel gibt es keine Vorgaben, das Kind geht seinen eigenen Interessen und Themen nach. In der Malpädagogik wird auch von der Bildsprache gesprochen, welche die Kinder ganz von selbst entwickeln. Dabei wird es aufmerksam begleitet und beim Einstieg einfühlsam unterstützt. Die wertungsfreie Grundhaltung ist ein wesentlicher Bestandteil des Malspiels. Das entstehende und das beendete Bild wird nicht bewertet. Weder die Betreuungsperson noch die anderen Gruppenmitglieder kommentieren, kritisieren, loben oder interpretieren das kreative schaffen des Kindes. Es steht der Prozess im Vordergrund und nicht das Ergebnis.
          </p><br>
          
          <p>Die Vorgehensweise des Malens verlagert sich zu einem spontanen Erforschen und Ausdrücken der Fantasie, Vorstellungskraft und des Unterbewusstseins. Das Kind erfährt die eigene Wirkung auf seine Umwelt und beginnt somit bewusst Verantwortung für sein Handeln zu entwickeln. Dadurch wird das Vertrauen in die eigenen Fähigkeiten erkannt und gestärkt.  
          </p><br>
          
          <p>Eine Umgebung speziell geschaffen, für die individuellen Bedürfnisse der Kinder, eine grosse Malpalette mit 18 leuchtenden Farben und spezielle Feehaarpinsel stehen bereit. In dieser vorbereiteten Lernumgebung wird im Stehen gemalt. Grosse Papierbögen werden an den dafür eingerichteten Holzverkleidungen auf Augenhöhe des Kindes befestigt und ermöglichen somit eine optimale Bewegungsfreiheit.
          </p><br>

          <p>Die grossen Malflächen bieten die Möglichkeit, Werke über mehrere Papierbögen hinweg wachsen zu lassen. Für einen kontinuierlichen Prozess werden die entstandenen Bilder datiert und archiviert und beim nächsten Besuch wieder aufgehängt. So kann über einen längeren Zeitraum hinweg, vertieft an einem Werk gearbeitet werden. Das Kind entscheidet selbst wann das Werk fertig ist.  
          </p><br>
        </div>
        <div class="col3">
          <img src="../assets/img/konzept.jpg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'KonzeptView',
    components: {
    },
    //---------------------------------------------------
    //
    //  Properties
    //
    //---------------------------------------------------
    props: {},
    //---------------------------------------------------
    //
    //  Data model
    //
    //---------------------------------------------------
    data() {
      return {};
    },
    //---------------------------------------------------
    //
    //  Computed Properties
    //
    //---------------------------------------------------
    computed: {},
    //---------------------------------------------------
    //
    //  Watch Properties
    //
    //---------------------------------------------------
    watch: {},
    //---------------------------------------------------
    //
    //  Filter Properties
    //
    //---------------------------------------------------
    // filters: {},
    //---------------------------------------------------
    //
    //  Validation Properties
    //
    //---------------------------------------------------
    // validations: {},
    //---------------------------------------------------
    //
    //  Vue Lifecycle
    //
    //---------------------------------------------------
    // beforeCreate() {},
    // created() {},
    // beforeMount() {},
    // render(h) { return h(); },
    // mounted() {},
    // beforeUpdate() {},
    // updated() {},
    // beforeDestroy() {},
    // destroyed() {},
    //---------------------------------------------------
    //
    //  Methods
    //
    //---------------------------------------------------
    methods: {},
  };
</script>
<style lang="scss">
</style>